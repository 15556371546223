APP.example = {

  init() {
    const view = this;

    view.getExamples();
  },

  getExamples() {
    $.ajax({
      url: wypAjax.url,
      method: 'POST',
      data: {
        action: 'get_examples',
        nonce: wypAjax.nonce,
        term_id: 1,
      },
      dataType: 'json',
      cache: false,
    }).done(() => {
      console.log('success');
    }).fail(() => {
      console.log('error');
    }).always(() => {
      console.log('complete');
    });
  },
};
