APP.slider = {
  init: function init() {
    var view = this;
    view.addSlider();
    view.addSliderTabs();
  },
  addSlider: function addSlider() {
    var swiper = new Swiper('.swiper-slider', {
      // Optional parameters
      direction: 'vertical',
      loop: true,
      autoplay: {
        delay: 14000
      },
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      speed: 1000,
    });
    // $('.arrow-slider').on("click", function () {
    //   swiper.slideNext();
    // });

    $("img.arrow-slider").on("click", function(event) {
      event.preventDefault(); // Prevent the default action

      // Get the offset of the next section (replace '.next-section' with the class or id of your next section)
      var nextSectionOffset = $('.imageText').offset().top;

      // Animate the scroll to the next section
      $('html, body').animate({
          scrollTop: nextSectionOffset,
          specialEasing: {
            width: "linear",
            height: "easeInOut"
          }
      }, 300); // The number 800 represents the speed of the animation in milliseconds
    });
  },
  addSliderTabs: function addSliderTabs() {
    var swiper = new Swiper('.swiper-sliderTabs', {
      // Optional parameters
      // direction: 'vertical',
      loop: true, // If we need pagination
      speed: 500,
    });
    $('.swiper-slide .link').on("click", function () {
      swiper.slideNext();
    });
  }
};