APP.header = {
  init: function init() {
    var view = this;
    view.openNcloseHeader();
  },
  openNcloseHeader: function openNcloseHeader() {
    $('.menu-open-mobile').on("click", function () {
      // console.log('aqui');
      $('.submenu-mobile').show();
    });
    $('.menu-close-mobile').on("click", function () {
      $('.submenu-mobile').hide();
    });
    $('.sidebar-mobile').on("click", function () {
      $('.sidebar-mobile').hide();
      // $('.sidebar-mobile-open').show();
      $('.sidebar-mobile-open').animate({
        height: "toggle"
      }, 300, function() {
        // Animation complete.
      });
    });
    $('.sidebar-mobile-open').on("click", function () {
      // $('.sidebar-mobile-open').hide();

      $('.sidebar-mobile-open').animate({
        height: "toggle"
      }, 300, function() {
        // Animation complete.
        
      });

      // $('.sidebar-mobile').show();
      $('.sidebar-mobile').fadeIn(300);

    });
    $('.submenu-mobile .main-link-wapper').on("click", function () {
      if ($(this).find('.sub-menu').css('display') == 'block') {
        $(this).find('.sub-menu').hide();
      } else {
        $('.sub-menu').hide();
        $(this).find('.sub-menu').show();
      }

      // console.log($(this).find('.sub-menu').css('display'));
    });
  }
};