APP.faqs = {

  init() {
    const view = this;

    view.addClick();
  },

  addClick() {
      $(document).on('click','.filter-faq',function (){
          const tab = $(this).data('tab')
          $('.filter-faq').removeClass('active');
          $('.section-faq').removeClass('active');
          $('.filter-faq[data-tab='+ tab +']').addClass('active')
          $('#'+ tab ).addClass('active')

          // Add a click event listener for the accordions in the active tab only
          $('.section-faq.active .accordion').on('click', function() {
              // Your accordion logic here
          });
      });
      $(document).on('click','.faqs-menu-nav',function (){
          // const tab = $(this).data('tab')
          // $('.filter-faq').removeClass('active');
          // $('.section-faq').removeClass('active');
          // $('.filter-faq[data-tab='+ tab +']').addClass('active')
          // $('#'+ tab ).addClass('active')

          // // Add a click event listener for the accordions in the active tab only
          // $('.section-faq.active .accordion').on('click', function() {
          //     // Your accordion logic here
          // });
          console.log('faqs')
      });
  },
};
