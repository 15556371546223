$ = jQuery;

const APP = {};
APP.example = {

  init() {
    const view = this;

    view.getExamples();
  },

  getExamples() {
    $.ajax({
      url: wypAjax.url,
      method: 'POST',
      data: {
        action: 'get_examples',
        nonce: wypAjax.nonce,
        term_id: 1,
      },
      dataType: 'json',
      cache: false,
    }).done(() => {
      console.log('success');
    }).fail(() => {
      console.log('error');
    }).always(() => {
      console.log('complete');
    });
  },
};

APP.faqs = {

  init() {
    const view = this;

    view.addClick();
  },

  addClick() {
      $(document).on('click','.filter-faq',function (){
          const tab = $(this).data('tab')
          $('.filter-faq').removeClass('active');
          $('.section-faq').removeClass('active');
          $('.filter-faq[data-tab='+ tab +']').addClass('active')
          $('#'+ tab ).addClass('active')

          // Add a click event listener for the accordions in the active tab only
          $('.section-faq.active .accordion').on('click', function() {
              // Your accordion logic here
          });
      });
      $(document).on('click','.faqs-menu-nav',function (){
          // const tab = $(this).data('tab')
          // $('.filter-faq').removeClass('active');
          // $('.section-faq').removeClass('active');
          // $('.filter-faq[data-tab='+ tab +']').addClass('active')
          // $('#'+ tab ).addClass('active')

          // // Add a click event listener for the accordions in the active tab only
          // $('.section-faq.active .accordion').on('click', function() {
          //     // Your accordion logic here
          // });
          console.log('faqs')
      });
  },
};

APP.header = {
  init: function init() {
    var view = this;
    view.openNcloseHeader();
  },
  openNcloseHeader: function openNcloseHeader() {
    $('.menu-open-mobile').on("click", function () {
      // console.log('aqui');
      $('.submenu-mobile').show();
    });
    $('.menu-close-mobile').on("click", function () {
      $('.submenu-mobile').hide();
    });
    $('.sidebar-mobile').on("click", function () {
      $('.sidebar-mobile').hide();
      // $('.sidebar-mobile-open').show();
      $('.sidebar-mobile-open').animate({
        height: "toggle"
      }, 300, function() {
        // Animation complete.
      });
    });
    $('.sidebar-mobile-open').on("click", function () {
      // $('.sidebar-mobile-open').hide();

      $('.sidebar-mobile-open').animate({
        height: "toggle"
      }, 300, function() {
        // Animation complete.
        
      });

      // $('.sidebar-mobile').show();
      $('.sidebar-mobile').fadeIn(300);

    });
    $('.submenu-mobile .main-link-wapper').on("click", function () {
      if ($(this).find('.sub-menu').css('display') == 'block') {
        $(this).find('.sub-menu').hide();
      } else {
        $('.sub-menu').hide();
        $(this).find('.sub-menu').show();
      }

      // console.log($(this).find('.sub-menu').css('display'));
    });
  }
};

APP.slider = {
  init: function init() {
    var view = this;
    view.addSlider();
    view.addSliderTabs();
  },
  addSlider: function addSlider() {
    var swiper = new Swiper('.swiper-slider', {
      // Optional parameters
      direction: 'vertical',
      loop: true,
      autoplay: {
        delay: 14000
      },
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      speed: 1000,
    });
    // $('.arrow-slider').on("click", function () {
    //   swiper.slideNext();
    // });

    $("img.arrow-slider").on("click", function(event) {
      event.preventDefault(); // Prevent the default action

      // Get the offset of the next section (replace '.next-section' with the class or id of your next section)
      var nextSectionOffset = $('.imageText').offset().top;

      // Animate the scroll to the next section
      $('html, body').animate({
          scrollTop: nextSectionOffset,
          specialEasing: {
            width: "linear",
            height: "easeInOut"
          }
      }, 300); // The number 800 represents the speed of the animation in milliseconds
    });
  },
  addSliderTabs: function addSliderTabs() {
    var swiper = new Swiper('.swiper-sliderTabs', {
      // Optional parameters
      // direction: 'vertical',
      loop: true, // If we need pagination
      speed: 500,
    });
    $('.swiper-slide .link').on("click", function () {
      swiper.slideNext();
    });
  }
};

$(() => {
  // APP.example.init();
  APP.faqs.init();
  APP.slider.init();
  // APP.sidebar.init();
  APP.header.init(); 
});

$(document).ready(function () {
  var sidebar = $('.sidebar');
  var sidebarHeight = sidebar.outerHeight(true);
  var footer = $('.footer');
  var initialSidebarTop = sidebar.offset().top;
  var footerTop = footer.offset().top;
  var scrollThreshold = 80; // adjust this value as needed
  var rightOffset = $(window).width() - (sidebar.offset().left + sidebar.outerWidth(true)); // initial right offset

  $(window).scroll(function () {
    var windowTop = $(window).scrollTop();

    if (windowTop > initialSidebarTop - scrollThreshold && windowTop < footerTop - sidebar.outerHeight(true) - sidebarHeight) {
      sidebar.addClass('fix-top').removeClass('bottom-reached');
      sidebar.css({
        'top': scrollThreshold + 'px',
        'right': rightOffset + 'px'
      });
    } else if (windowTop >= footerTop - sidebar.outerHeight(true) - sidebarHeight && windowTop < footerTop - scrollThreshold ) {
      sidebar.addClass('bottom-reached').removeClass('fix-top');
      sidebar.css({
        'top': (footerTop - sidebar.outerHeight(true) - sidebarHeight - scrollThreshold - 130) + 'px',
        'right': 8 + '%'
      });
    } else {
      sidebar.removeClass('fix-top');
      sidebar.css({
        'top': '',
        'right': ''
      });
    }
  });

  $(window).resize(function () {
    rightOffset = $(window).width() - (sidebar.offset().left + sidebar.outerWidth(true));
    sidebar.css('right', rightOffset + 'px');
  });

  $(document).on('click', '.accordion-button', function () {
    console.log('accordion-button click handler');
    //Get the aria extended value
    var ariaExpanded = $(this).attr('aria-expanded');
    console.log('ariaExpanded', ariaExpanded);

    //Check if is mobile device
    if (window.matchMedia("(max-width: 767px)").matches) {

        //Animate scroll to accordion header inside a timeout
        setTimeout(function () {
            //Get the offset position of the accordion header
            var offset = $(this).offset().top;

            //Get header.color-white height
            var headerHeight = $('.header.color-white').outerHeight(true);

            $('html, body').animate({
                scrollTop: offset - headerHeight
            }, 200); // change 500 to the desired scroll time in milliseconds
        }.bind(this), 500);  // bind 'this' to the function to get the correct element
    }
});


});

$(document).on('click', 'sup', function () {
  console.log('sup click handler');
  if(!$('.section-faq.active .accordion').length) {
    $('html, body').animate({
      scrollTop: $(".refs").offset().top
  });
  }
  
});

// $(document).ready(function(){
//   $(".menu-open-mobile, .menu-close-mobile").click(function(){
//     $(".submenu-mobile").slideToggle();
//   });
// });

$(document).on('click', 'sup', function () {
    // Get the closest parent 'section-faq' element
    var closestSection = $(this).closest('.section-faq');

    // Check its id to determine which 'refs' section to scroll to
    var refsId = (closestSection.attr('id') === 'ovodoacao') ? '#refs1' : '#refs2';

    $('html, body').animate({
        scrollTop: $(refsId).offset().top
    });
});


function hashchanged() {
  var hash = location.hash;
  var hash_replaced = hash.replace("#", "");
  // console.log("🚀 ~ file: main.js:xx ~ hashchanged ~ hash:", hash);
  
  // Check if hash is not empty
  if(hash) {
    $('.filter-faq[data-tab="' + hash_replaced + '"]').click();
     // Scroll to the element
     $('html, body').animate({
      scrollTop: $('.filter-faq[data-tab="' + hash_replaced + '"]').offset().top
    }, 500);
  } else {
    console.log("Hash is empty!");
  }
}

window.addEventListener("hashchange", hashchanged, false);
